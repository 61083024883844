import React, {useContext} from "react";
import "./article-list.scss"
import { GatsbyImage } from "gatsby-plugin-image";
import {Link} from "gatsby";
import {SiteContext} from "../../context/site-context";

const ArticleList = (props) => {

  const siteContext = useContext(SiteContext);
  const i18next = siteContext.i18next;

  let date = new Date(props.entity.created * 1000);

  const monthNames = [
    i18next.t('date:January'),
    i18next.t('date:February'),
    i18next.t('date:March'),
    i18next.t('date:April'),
    i18next.t('date:May'),
    i18next.t('date:June'),
    i18next.t('date:July'),
    i18next.t('date:August'),
    i18next.t('date:September'),
    i18next.t('date:October'),
    i18next.t('date:November'),
    i18next.t('date:December'),
  ];

return (
    <Link className="article-list-item" to={props.entity.entityUrl.path}>
      <GatsbyImage image={{...props.entity.fieldImage.imageGatsbyFile.childImageSharp.gatsbyImageData}} />
      <div className="article-info">
        <h2>{props.entity.entityLabel}</h2>
        <span className="meta-value">{`${date.getDate()} ${monthNames[date.getMonth()]} ${date.getFullYear()}`}</span>
      </div>
    </Link>
  )
};

export default ArticleList;
